import { Button, Divider, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LanguageIcon from "@mui/icons-material/Language";

import i18n from "../Locales/i18n";

export default function LanguageSwitchButton() {
  const languageItems = ["ko", "en"];
  return (
    <div id="languageSwitchButton">
      <LanguageIcon className="icon" />
      <div className="buttonWrapper">
        {languageItems
          // .sort(function (a, b) {
          //   if (i18n.language === a && i18n.language !== b) {
          //     return -1;
          //   }
          //   if (i18n.language !== a && i18n.language === b) {
          //     return 1;
          //   }
          //   return a - b;
          // })
          .reverse()
          .map((item, idx) => (
            <div className="buttonWrapper" key={idx}>
              {idx !== 0 && <Box className="divider" />}
              <Button
                className={
                  i18n.language === item
                    ? "toggleButton selected"
                    : "toggleButton"
                }
                onClick={() => {
                  i18n.changeLanguage(item);
                }}
              >
                {item}
              </Button>
            </div>
          ))}
      </div>
    </div>
  );
}
