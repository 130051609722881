import * as React from "react";
import { Button, Stack } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MenuIcon from "@mui/icons-material/Menu";

import ScrollToTop from "../Tools/ScrollToTop";
import { ChevronRight, Logout } from "@mui/icons-material";
import useDeviceType from "../Tools/useDeviceType";

import LanguageSwitchButton from "./LanguageSwitchButton";

export default function Header() {
  const { t } = useTranslation();

  const navItems = [
    {
      text: t("header-navItem-1"),
      subPage: [
        {
          text: t("header-navItem-1-subPage-1"),
          link: "/product/medistep",
        },
        {
          text: t("header-navItem-1-subPage-2"),
          link: "/product/medistepm",
        },
        {
          text: t("header-navItem-1-subPage-3"),
          link: "/product/gaitstudio",
        },
      ],
    },
    {
      text: t("header-navItem-2"),
      subPage: [
        {
          text: t("header-navItem-2-subPage-1"),
          link: "/about/company",
        },
        {
          text: t("header-navItem-2-subPage-2"),
          link: "/about/usage",
        },
        {
          text: t("header-navItem-2-subPage-3"),
          link: "/about/news",
        },
      ],
    },
  ];

  const navigate = useNavigate();

  const { isMobile, isDesktop } = useDeviceType();
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <header>
      <Stack
        className="leading"
        direction="row"
        alignItems="center"
        spacing={3}
      >
        <Button
          onClick={() => {
            ScrollToTop();
            navigate("/");
          }}
        >
          <img src="/logo_ait.svg" alt="AIT Studio" />
        </Button>
        <LanguageSwitchButton />
      </Stack>

      {isDesktop && (
        <nav>
          {navItems.map((item, idx) => {
            return (
              <DropdownBtn key={idx} text={item.text} subPage={item.subPage} />
            );
          })}
        </nav>
      )}

      {isMobile && (
        <>
          <Button
            onClick={() => {
              setShowSidebar((prev) => !prev);
            }}
          >
            <MenuIcon />
          </Button>

          {showSidebar && (
            <aside>
              <div className="content">
                <Button
                  onClick={() => {
                    setShowSidebar((prev) => !prev);
                  }}
                >
                  <Logout />
                </Button>
                <nav>
                  {navItems.map((item, idx) => {
                    return (
                      <PageList
                        key={idx}
                        text={item.text}
                        subPage={item.subPage}
                      />
                    );
                  })}
                </nav>
              </div>
              <Button
                onClick={() => {
                  setShowSidebar((prev) => !prev);
                }}
                className="backdrop"
              />
            </aside>
          )}
        </>
      )}
    </header>
  );
}

function DropdownBtn({ text, subPage }) {
  const navigate = useNavigate();

  return (
    <div className="dropdownBtn">
      <p className="mainPage">{text}</p>
      <Stack className="subPages">
        {subPage.map((page, idx) => {
          return (
            <Button
              key={idx}
              onClick={() => {
                if (page.link) {
                  ScrollToTop();
                  navigate(page.link);
                }
              }}
            >
              {page.text}
            </Button>
          );
        })}
      </Stack>
    </div>
  );
}

function PageList({ text, subPage }) {
  const navigate = useNavigate();

  return (
    <div className="pageList">
      <p className="mainPage">{text}</p>
      <div className="subPages">
        {subPage.map((page, idx) => {
          return (
            <Button
              key={idx}
              onClick={() => {
                if (page.link) {
                  ScrollToTop();
                  navigate(page.link);
                }
              }}
            >
              <p>{page.text}</p>
              <ChevronRight />
            </Button>
          );
        })}
      </div>
    </div>
  );
}
