import { Button } from "@mui/material";
import ScrollToTop from "../Tools/ScrollToTop";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ProductCommonViewMore({ cardSrc }) {
  const { t } = useTranslation();

  return (
    <section className="productCommon" id="viewMore">
      <div className="general">
        <p>{t("product-common-viewMore-title")}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="140"
          height="16"
          viewBox="0 0 140 16"
          fill="none"
        >
          <path
            d="M139.707 8.70711C140.098 8.31658 140.098 7.68342 139.707 7.29289L133.343 0.928932C132.953 0.538408 132.319 0.538408 131.929 0.928932C131.538 1.31946 131.538 1.95262 131.929 2.34315L137.586 8L131.929 13.6569C131.538 14.0474 131.538 14.6805 131.929 15.0711C132.319 15.4616 132.953 15.4616 133.343 15.0711L139.707 8.70711ZM0 9H139V7H0V9Z"
            fill="black"
          />
        </svg>
      </div>
      <div className="cardWrapper">
        {cardSrc.map((item, idx) => (
          <ViewMoreCard
            key={idx}
            logoSrc={item.logoSrc}
            imgSrc={item.imgSrc}
            link={item.link}
          />
        ))}
      </div>
    </section>
  );
}

function ViewMoreCard({ logoSrc, imgSrc, link }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="viewMoreCard">
      <img className="logo" src={logoSrc} alt="Logo" />
      <img className="product" src={imgSrc} alt="Product img" />
      <Button
        onClick={() => {
          if (link) {
            ScrollToTop();
            navigate(link);
          }
        }}
      >
        {t("product-common-viewMore-cardButton")}
      </Button>
    </div>
  );
}
