import { Routes, Route, HashRouter } from "react-router-dom";

import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

import MainPage from "./Pages/MainPage";
import AboutCompanyPage from "./Pages/AboutCompanyPage";
import AboutUsagePage from "./Pages/AboutUsagePage";
import AboutNewsPage from "./Pages/AboutNewsPage";
import ProductMedistepPage from "./Pages/ProductMedistepPage";
import ProductMedistepMPage from "./Pages/ProductMedistepMPage";
import ProductGaitStudioPage from "./Pages/ProductGaitStudioPage";

const THEME = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: 0,
          textTransform: "none",

          ":hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: `"Inter", "Pretendard", "Helvetica", "Arial", sans-serif`,
  },
});

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <HashRouter>
        <Routes>
          {/* Main */}
          <Route path="/" exact element={<MainPage />} />

          {/* About */}
          <Route path="/about/company" exact element={<AboutCompanyPage />} />
          <Route path="/about/usage" exact element={<AboutUsagePage />} />
          <Route path="/about/news" exact element={<AboutNewsPage />} />

          {/* Product */}
          <Route
            path="/product/medistep"
            exact
            element={<ProductMedistepPage />}
          />
          <Route
            path="/product/medistepm"
            exact
            element={<ProductMedistepMPage />}
          />
          <Route
            path="/product/gaitstudio"
            exact
            element={<ProductGaitStudioPage />}
          />
        </Routes>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
