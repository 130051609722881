import { Button } from "@mui/material";

export default function ProductCommonInfo({
  logoSrc,
  imgSrc,
  linkImgTo,
  description,
  children,
}) {
  return (
    <section className="productCommon" id="info">
      <div className="headline">
        <img className="logo" src={logoSrc} alt="Logo" />
        <div className="divider" />
        <p className="description">{description}</p>
      </div>

      <article>
        {linkImgTo ? (
          <a
            href={linkImgTo}
            target="_blank"
            rel="noreferrer"
            className="productWrapper"
          >
            <img src={imgSrc} alt="Product img" />
          </a>
        ) : (
          <div className="productWrapper">
            <img className="product" src={imgSrc} alt="Product img" />
          </div>
        )}
        <div className="children">{children}</div>
      </article>
    </section>
  );
}
