import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ProductCommonBanner from "../Components/ProductCommonBanner";
import ProductCommonViewMore from "../Components/ProductCommonViewMore";
import ProductCommonInfo from "../Components/ProductCommonInfo";
import { MedistepPoints } from "./ProductMedistepPage";
import { useTranslation } from "react-i18next";

export default function ProductMedistepMPage() {
  const { t } = useTranslation();

  return (
    <div className="page product" id="medistepM">
      <Header />
      <main>
        <ProductCommonBanner />
        <ProductCommonInfo
          logoSrc="/Product/MedistepM/logo.png"
          imgSrc="/Product/MedistepM/landing.png"
          description={t("product-medistepM-info-description")}
        >
          <p>
            <span>{t("product-medistepM-info-children-1-bold-1")}</span>
            {t("product-medistepM-info-children-1-regular-1")}
            <span>{t("product-medistepM-info-children-1-bold-2")}</span>
            {t("product-medistepM-info-children-1-regular-2")}
          </p>
          <p>{t("product-medistepM-info-children-2")}</p>
        </ProductCommonInfo>
        <MedistepPoints />
        <ProductCommonViewMore
          cardSrc={[
            {
              logoSrc: "/Main/logo-medistep.png",
              imgSrc: "/Main/product-medistep.png",
              link: "/product/medistep",
            },
            {
              logoSrc: "/Main/logo-gaitstudio.png",
              imgSrc: "/Main/product-gaitstudio.png",
              link: "/product/gaitstudio",
            },
          ]}
        />
      </main>
      <Footer />
    </div>
  );
}
