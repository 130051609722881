export default function scrollToId(id, offset = 0) {
  const element = document.getElementById(id);

  if (element) {
    const offsetTop = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: offsetTop + offset,
      behavior: "smooth",
    });
  }
}
