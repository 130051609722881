import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Button, Stack } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { CallMade, KeyboardDoubleArrowRight } from "@mui/icons-material";

import { useTranslation } from "react-i18next";

import scrollToId from "../Tools/ScrollToId";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../Tools/ScrollToTop";
import useDeviceType from "../Tools/useDeviceType";
import { useEffect, useRef } from "react";
import MainBanner from "../Components/MainBanner";

function Landing() {
  const { t } = useTranslation();

  return (
    <section id="landing">
      <article>
        <div>
          <p>{t("main-landing-header")}</p>
          <div id="divider" />
        </div>
        <p className="headline">{t("main-landing-headline")}</p>
        <p className="description">
          {t("main-landing-description-1")}
          <br />
          {t("main-landing-description-2")}
        </p>
      </article>

      <ScrollDown />
    </section>
  );

  function ScrollDown() {
    return (
      <Button
        className="scrollDown"
        onClick={() => {
          scrollToId("steps", -75);
        }}
      >
        <img src="/chevron-down.svg" alt="scroll down" />
        <p>{t("main-landing-scrollDown")}</p>
      </Button>
    );
  }
}

function Steps() {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useDeviceType();

  return (
    <section id="steps">
      {isMobile && (
        <img src="/Main/steps-mobile.png" alt="Gait Analyse steps" />
      )}
      {isDesktop && (
        <img src="/Main/steps-desktop.png" alt="Gait Analyse steps" />
      )}
    </section>
  );
}

function Solution() {
  const { t } = useTranslation();
  return (
    <section id="solution">
      <p className="sectionTitle">Our Solution</p>
      <article>
        <div>
          <p className="headline">
            {t("main-solution-title-1")}
            <br />
            {t("main-solution-title-2")}
          </p>
          <p>
            {t("main-solution-paragraph-1-1")}
            <br />
            {t("main-solution-paragraph-1-2")}
            <br />
            {t("main-solution-paragraph-1-3")}
          </p>
          <p>
            {t("main-solution-paragraph-2-1")}
            <br />
            {t("main-solution-paragraph-2-2")}
            <br />
            {t("main-solution-paragraph-2-3")}
          </p>
          <a
            href="https://drive.google.com/file/d/1tqi7KF5Lv_IgMgSlSQV5qXT0Gy3d_d9v/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <div className="viewPDF">
              <p>{t("main-solution-viewWhitePaper")}</p>
              <CallMade />
            </div>
          </a>
        </div>
      </article>
    </section>
  );
}

function Technology() {
  const { t } = useTranslation();

  const techs = [
    {
      imgSrc: "/Main/tech-1.png",
      title: (
        <p>
          {t("main-technology-1-title-1")}
          <br />
          {t("main-technology-1-title-2")}
        </p>
      ),
      caption: t("main-technology-1-caption"),
    },
    {
      imgSrc: "/Main/tech-2.png",
      title: (
        <p>
          {t("main-technology-2-title-1")}
          <br />
          {t("main-technology-2-title-2")}
        </p>
      ),
      caption: t("main-technology-2-caption"),
    },
    {
      imgSrc: "/Main/tech-3.png",
      title: (
        <p>
          {t("main-technology-3-title-1")}
          <br />
          {t("main-technology-3-title-2")}
        </p>
      ),
      caption: t("main-technology-3-caption"),
    },
    {
      imgSrc: "/Main/tech-4.png",
      title: (
        <p>
          {t("main-technology-4-title-1")}
          <br />
          {t("main-technology-4-title-2")}
        </p>
      ),
      caption: t("main-technology-4-caption"),
    },
    {
      imgSrc: "/Main/tech-5.png",
      title: (
        <p>
          {t("main-technology-5-title-1")}
          <br />
          {t("main-technology-5-title-2")}
        </p>
      ),
      caption: t("main-technology-5-caption"),
    },
  ];

  return (
    <section id="technology">
      <div className="card">
        <img src="/Main/tech-0.png" alt="Our technology" />
      </div>
      {techs.map((item, idx) => (
        <TechCard key={idx} tech={item} />
      ))}
    </section>
  );

  function TechCard({ tech }) {
    return (
      <div className="card tech">
        <div
          className="side front"
          style={{
            background: `linear-gradient(rgba(255, 255, 255, 0),
                rgba(119, 119, 119, 0.9)), url(${tech.imgSrc}) no-repeat center center`,
            backgroundSize: "cover",
          }}
        >
          {tech.title}
          <div className="circle" />
        </div>
        <div className="side back">
          {tech.title}
          <div className="divider" />
          <p className="caption">{tech.caption}</p>
        </div>
      </div>
    );
  }
}

function ProductLineUp() {
  const { t } = useTranslation();

  const lineUp = [
    {
      logoSrc: "/Main/logo-medistep.png",
      imgSrc: "/Main/product-medistep.png",
      link: "/product/medistep",
      children: (
        <p>
          <span>{t("main-productLineUp-medistep-1-bold")}</span>
          {t("main-productLineUp-medistep-1-regular")}
          <br />
          {t("main-productLineUp-medistep-2").length > 0 && (
            <>
              {t("main-productLineUp-medistep-2")}
              <br />
            </>
          )}
          {t("main-productLineUp-medistep-3-regular-1")}
          <span>{t("main-productLineUp-medistep-3-bold")}</span>
          {t("main-productLineUp-medistep-3-regular-2")}
          <br />
          {t("main-productLineUp-medistep-4")}
          <br />
          {t("main-productLineUp-medistep-5")}
          <br />
          {t("main-productLineUp-medistep-6")}
        </p>
      ),
    },
    {
      logoSrc: "/Main/logo-medistepM.png",
      imgSrc: "/Main/product-medistepM.png",
      link: "/product/medistepm",
      children: (
        <p>
          <span>{t("main-productLineUp-medistepM-1-bold-1")}</span>
          {t("main-productLineUp-medistepM-1-regular-1")}
          <span>{t("main-productLineUp-medistepM-1-bold-2")}</span>
          {t("main-productLineUp-medistepM-1-regular-2")}
          <br />
          {t("main-productLineUp-medistepM-2")}
          <br />
          {t("main-productLineUp-medistepM-3-regular-1")}
          <span>{t("main-productLineUp-medistepM-3-bold")}</span>
          {t("main-productLineUp-medistepM-3-regular-2")}
          <br />
          {t("main-productLineUp-medistepM-4")}
          <br />
          {t("main-productLineUp-medistepM-5")}
          <br />
          {t("main-productLineUp-medistepM-6")}
        </p>
      ),
    },
    {
      logoSrc: "/Main/logo-gaitstudio.png",
      imgSrc: "/Main/product-gaitstudio.png",
      link: "/product/gaitstudio",
      children: (
        <p>
          <span>{t("main-productLineUp-gaitstudio-1-bold")}</span>
          {t("main-productLineUp-gaitstudio-1-regular")}
          <br />
          {t("main-productLineUp-gaitstudio-2-regular-1")}
          <span>{t("main-productLineUp-gaitstudio-2-bold")}</span>
          {t("main-productLineUp-gaitstudio-2-regular-2")}
          <br />
          {t("main-productLineUp-gaitstudio-3")}
          <br />
          {t("main-productLineUp-gaitstudio-4")}
          <br />
          {t("main-productLineUp-gaitstudio-5")}
        </p>
      ),
    },
  ];

  return (
    <section id="productLineUp">
      <p className="sectionTitle">Product Line-up</p>
      <article>
        {lineUp.map((item, idx) => (
          <ProductCard key={idx} product={item} />
        ))}
      </article>
    </section>
  );

  function ProductCard({ product }) {
    const navigate = useNavigate();

    return (
      <div className="productCard">
        <img className="logo" src={product.logoSrc} alt="Logo" />
        <div className="thumbnail">
          <img className="product" src={product.imgSrc} alt="Product img" />
          <div className="children">{product.children}</div>
        </div>
        <Button
          onClick={() => {
            if (product.link) {
              ScrollToTop();
              navigate(product.link);
            }
          }}
        >
          {t("main-productLineUp-viewDetails")}
        </Button>
      </div>
    );
  }
}

function News() {
  const { t } = useTranslation();

  const news = [
    {
      imgSrc: "/Main/news-1.png",
      title: t("main-news-1-title"),
      caption: t("main-news-1-caption"),
      link: "https://kr.aving.net/news/articleView.html?idxno=1794499",
    },
    {
      imgSrc: "/Main/news-2.jpg",
      title: t("main-news-2-title"),
      caption: t("main-news-2-caption"),
      link: "https://kr.aving.net/news/articleView.html?idxno=1794423",
    },
    {
      imgSrc: "/Main/news-3.jpg",
      title: t("main-news-3-title"),
      caption: t("main-news-3-caption"),
      link: "https://www.etnews.com/20240906000033",
    },
    {
      imgSrc: "/Main/news-4.jpg",
      title: t("main-news-4-title"),
      caption: t("main-news-4-caption"),
      link: "https://www.seongdongnews.com/news/articleView.html?idxno=30779",
    },
    {
      imgSrc: "/Main/news-5.jpg",
      title: t("main-news-5-title"),
      caption: t("main-news-5-caption"),
      link: "https://www.newscj.com/news/articleView.html?idxno=3113969",
    },
  ];

  return (
    <section id="news">
      <article>
        <p className="sectionTitle">News</p>
        <Swiper
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {news.map((item, idx) => (
            <SwiperSlide key={idx}>
              <NewsSlide news={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </article>
    </section>
  );

  function NewsSlide({ news }) {
    return (
      <div
        className="newsSlide"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.65)), url(${news.imgSrc}) no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <p className="title">{news.title}</p>
        <p className="caption">{news.caption}</p>

        <a href={news.link} target="_blank" rel="noreferrer">
          <Stack direction="row" alignItems="center" className="viewMore">
            <KeyboardDoubleArrowRight />
            <p>{t("main-news-viewMore")}</p>
          </Stack>
        </a>
      </div>
    );
  }
}

function ContactUs() {
  const { t } = useTranslation();

  return (
    <section id="contactUs">
      <p className="sectionTitle">Contact Us</p>
      <article>
        <div>
          <p>
            {t("main-contactUs-title-1")}
            <br />
            {t("main-contactUs-title-2")}
          </p>
          <a
            href="mailto:amazing@aitstudio.co.kr"
            target="_blank"
            rel="noreferrer"
          >
            {t("main-contactUs-inquiry")}
          </a>
        </div>
        {/* <iframe
          src="https://www.youtube.com/embed/bo9a8hObVCU"
          title="&#39;AI&#39; 촬영으로 질병 찾고, 거울로 혈압 측정까지 / SBS"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe> */}
        <video controls autoPlay loop muted>
          <source src={"/contactus_250121_sbs.mp4"} type="video/mp4" />
        </video>
      </article>
    </section>
  );
}

function Partners() {
  const { t } = useTranslation();

  const partners = [
    {
      imgSrc: "/Main/Partners/logo-apple.png",
      caption: "Apple",
    },
    {
      imgSrc: "/Main/Partners/logo-eisai.png",
      caption: "Eisai",
    },
    {
      imgSrc: "/Main/Partners/logo-kdb2024.png",
      caption: "KDB Start Up 2024",
    },
    {
      imgSrc: "/Main/Partners/logo-kitech.png",
      caption: "KITECH",
    },
    {
      imgSrc: "/Main/Partners/logo-koreaUniv.png",
      caption: "Korea Univercity",
    },
    {
      imgSrc: "/Main/Partners/logo-lg.png",
      caption: "LG",
    },
    {
      imgSrc: "/Main/Partners/logo-mysc.png",
      caption: "mysc",
    },
    {
      imgSrc: "/Main/Partners/logo-snuh.png",
      caption: "SNUH",
    },
  ];

  return (
    <section id="partners">
      <p className="sectionTitle">Partners</p>
      <article className="window">
        <div className="content">
          {partners.map((item, idx) => (
            <PartnerCard key={idx} partner={item} />
          ))}

          <div className="tail">
            {partners.map((item, idx) => (
              <PartnerCard key={idx} partner={item} />
            ))}
          </div>
        </div>
      </article>
    </section>
  );

  function PartnerCard({ partner }) {
    return (
      <div className="partnerCard">
        <img src={partner.imgSrc} alt={partner.caption} />
        {/* <p>{poc.caption}</p> */}
      </div>
    );
  }
}

export default function MainPage() {
  return (
    <div className="page" id="main">
      <Header />
      <main>
        <MainBanner />
        <Landing />
        <Steps />
        <ProductLineUp />
        <Solution />
        <Technology />
        <News />
        <ContactUs />
        <Partners />
      </main>
      <Footer />
    </div>
  );
}
