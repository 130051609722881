import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ProductCommonBanner from "../Components/ProductCommonBanner";
import ProductCommonViewMore from "../Components/ProductCommonViewMore";
import ProductCommonInfo from "../Components/ProductCommonInfo";
import { useTranslation } from "react-i18next";

export default function ProductGaitStudioPage() {
  const { t } = useTranslation();

  return (
    <div className="page product" id="gaitStudio">
      <Header />
      <main>
        <ProductCommonBanner />
        <ProductCommonInfo
          logoSrc="/Product/GaitStudio/logo.png"
          imgSrc="/Product/GaitStudio/landing.png"
          linkImgTo="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=6739551581"
          description={t("product-gaitstudio-info-description")}
        >
          <p>
            <span>{t("product-gaitstudio-info-children-1-bold")}</span>
            {t("product-gaitstudio-info-children-1-regular")}
          </p>
          <p>{t("product-gaitstudio-info-children-2")}</p>
        </ProductCommonInfo>
        <ProductCommonViewMore
          cardSrc={[
            {
              logoSrc: "/Main/logo-medistep.png",
              imgSrc: "/Main/product-medistep.png",
              link: "/product/medistep",
            },
            {
              logoSrc: "/Main/logo-medistepM.png",
              imgSrc: "/Main/product-medistepM.png",
              link: "/product/medistepm",
            },
          ]}
        />
      </main>
      <Footer />
    </div>
  );
}
