import { useTranslation } from "react-i18next";

export default function ProductCommonBanner() {
  const { t } = useTranslation();

  return (
    <section className="productCommon" id="banner">
      <p>
        {t("product-common-banner-title-1")}
        <br />
        {t("product-common-banner-title-2")}
      </p>
      <a href="mailto:amazing@aitstudio.co.kr" target="_blank" rel="noreferrer">
        {t("product-common-banner-inquiry")}
      </a>
    </section>
  );
}
