import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ProductCommonBanner from "../Components/ProductCommonBanner";
import ProductCommonViewMore from "../Components/ProductCommonViewMore";
import ProductCommonInfo from "../Components/ProductCommonInfo";
import ProductCommonPointHeadline from "../Components/ProductCommonPoint";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";

export default function ProductMedistepPage() {
  const { t } = useTranslation();

  return (
    <div className="page product" id="medistep">
      <Header />
      <main>
        <ProductCommonBanner />
        <ProductCommonInfo
          logoSrc="/Product/Medistep/logo.png"
          imgSrc="/Product/Medistep/landing.png"
          description={t("product-medistep-info-description")}
        >
          <p>
            <span>{t("product-medistep-info-children-1-bold")}</span>
            {t("product-medistep-info-children-1-regular")}
          </p>
          <p>{t("product-medistep-info-children-2")}</p>
        </ProductCommonInfo>
        <MedistepPoints />
        <ProductCommonViewMore
          cardSrc={[
            {
              logoSrc: "/Main/logo-medistepM.png",
              imgSrc: "/Main/product-medistepM.png",
              link: "/product/medistepm",
            },
            {
              logoSrc: "/Main/logo-gaitstudio.png",
              imgSrc: "/Main/product-gaitstudio.png",
              link: "/product/gaitstudio",
            },
          ]}
        />
      </main>
      <Footer />
    </div>
  );
}

export function MedistepPoints() {
  const { t, i18n } = useTranslation();
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.load();
    }
  }, [i18n.language]);

  return (
    <section className="points">
      <div className="point">
        <ProductCommonPointHeadline
          chip={t("product-medistep-point-1-chip")}
          title={t("product-medistep-point-1-title")}
        />
        <div className="content">
          <article>
            <img
              src="/Product/Medistep/point-1.png"
              alt="Details, Print Report, Parameters"
            />
          </article>

          <article>
            <video ref={videoRef} controls autoPlay loop muted>
              <source
                src={`./about_medistep_720p_${i18n.language}.mp4`}
                type="video/mp4"
              />
            </video>
          </article>
        </div>
      </div>
      <div className="point">
        <ProductCommonPointHeadline
          chip={t("product-medistep-point-2-chip")}
          title={t("product-medistep-point-2-title")}
        />
        <div className="content">
          <img src="/Product/Medistep/point-2.png" alt="Certificates" />
        </div>
      </div>
      <div className="point">
        <ProductCommonPointHeadline
          chip={t("product-medistep-point-3-chip")}
          title={t("product-medistep-point-3-title")}
        />
        <div className="content">
          <img src="/Product/Medistep/point-3.png" alt="Poc" />
        </div>
      </div>
    </section>
  );
}
