import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { useCallback, useMemo, useRef, useState } from "react";

export default function MainBanner() {
  const { i18n } = useTranslation();

  const banners = useMemo(() => {
    return {
      en: [
        {
          imgSrc: "url(/Main/Banners/banner-250116-gaitstudio-en.png)",
          linkTo:
            "https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=6739551581",
        },
      ],
      ko: [
        {
          imgSrc: "url(/Main/Banners/banner-250116-gaitstudio-ko.png)",
          linkTo:
            "https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=6739551581",
        },
        {
          imgSrc: "url(/Main/Banners/banner-250116-medistep-ko.png)",
          linkTo: "https://venture.g2b.go.kr/",
        },
      ],
    };
  }, []);
  const currentBanners = useMemo(
    () => banners[i18n.language],
    [banners, i18n.language]
  );

  const [swiperIdx, setSwiperIdx] = useState(0);
  const swiperRef = useRef(null);

  const prevPageAvailable = useMemo(() => {
    return swiperIdx;
  }, [swiperIdx]);

  const nextPageAvailable = useMemo(() => {
    return swiperIdx !== currentBanners.length - 1;
  }, [currentBanners.length, swiperIdx]);

  const toPrevSlide = useCallback(() => {
    if (prevPageAvailable) {
      swiperRef?.current?.swiper.slidePrev();
    } else {
      swiperRef?.current?.swiper.slideTo(currentBanners.length - 1);
    }
  }, [currentBanners.length, prevPageAvailable]);

  const toNextSlide = useCallback(() => {
    if (nextPageAvailable) {
      swiperRef?.current?.swiper.slideNext();
    } else {
      swiperRef?.current?.swiper.slideTo(0);
    }
  }, [nextPageAvailable]);

  return (
    <div id="mainBanner">
      <Swiper
        ref={swiperRef}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        onSlideChange={(data) => {
          setSwiperIdx(data.activeIndex);
        }}
      >
        {currentBanners.map((item, idx) => (
          <SwiperSlide key={idx}>
            <a href={item.linkTo} target="_blank" rel="noreferrer">
              <Box
                className="banner"
                sx={{
                  backgroundImage: item.imgSrc,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>

      <div id="navigator">
        <Button
          onClick={() => {
            toPrevSlide();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="14"
            viewBox="0 0 6 14"
            fill="none"
          >
            <path
              d="M4.4459 13.5L0 6.72399L4.22951 0.5H5.78361L1.63279 6.72399L6 13.5H4.4459Z"
              fill="white"
            />
          </svg>
        </Button>
        <p>{`${swiperIdx + 1} / ${currentBanners.length}`}</p>
        <Button
          onClick={() => {
            toNextSlide();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="14"
            viewBox="0 0 6 14"
            fill="none"
          >
            <path
              d="M1.5541 13.5L6 6.72399L1.77049 0.5H0.216393L4.36721 6.72399L0 13.5H1.5541Z"
              fill="white"
            />
          </svg>
        </Button>
      </div>
    </div>
  );
}
