import * as React from "react";
import { useTranslation } from "react-i18next";
import ScrollToTopBtn from "./ScrollTopBtn";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <img src="/logo_ait.svg" alt="AIT Studio" />
      <section>
        <article>
          <p>{t("footer-contactUs")}</p>
          <p>{t("footer-location")}</p>
        </article>

        <article>
          <p id="copyright">{t("footer-copyright")}</p>
        </article>
      </section>
      <ScrollToTopBtn />
    </footer>
  );
}
